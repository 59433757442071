<template>
  <Disclosure as="nav" class="" v-slot="{ open, close }">
    <header
      :class="`relative mx-auto bg-primary-700 px-4 text-white sm:pb-0 md:px-6 ${
        !open ? 'pb-4' : ''
      }`"
    >
      <div :class="`flex flex-col justify-between gap-4 md:flex-row`">
        <div class="flex flex-col">
          <!-- mobile only -->
          <div :class="`mb-1 mt-3 md:hidden`">
            <DisclosureButton
              class="inline-flex items-center justify-center rounded-md focus:outline-none"
            >
              <span class="sr-only">Open main menu</span>
              <!-- hamburger button -->
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <!-- close button-->
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <!-- mobile + desktop -->
          <div
            :class="`${
              !open ? 'hidden' : ''
            } flex flex-col overflow-y-auto py-2 md:ml-0 md:block md:flex-row md:p-0`"
          >
            <router-link
              v-for="item in menuItems"
              :key="`${item.name}
              ${userPersistStore.errors.length}`"
              :to="{ name: item.name }"
              @click="close()"
              :class="`my-1 inline-flex h-full items-center rounded-md px-2 py-2 pr-4 md:my-0 md:mr-2 md:rounded-none md:px-2 md:py-3`"
            >
              <component :is="item.meta.icon" :class="`mr-0 h-4 w-5 md:h-5`" />
              <div class="ml-1">
                {{ item.name }}
                <span
                  v-if="item.name === 'Errors'"
                  class="text-[0.7rem]"
                  :class="{
                    'ml-1 whitespace-nowrap rounded-full bg-error py-0.5':
                      item.name === 'Errors' &&
                      userPersistStore.errors.length > 0,
                    'px-1.5': userPersistStore.errors.length < 10,
                    'px-1': userPersistStore.errors.length >= 10,
                  }"
                  >{{ userPersistStore.errors.length }}</span
                >
                <span
                  v-if="item.name === 'HR' && hrStore.openApprovals.length > 0"
                  class="ml-1 whitespace-nowrap rounded-full bg-status-pending py-0.5 text-[0.7rem]"
                  :class="{
                    'px-1.5': hrStore.openApprovals.length < 10,
                    'px-1': hrStore.openApprovals.length >= 10,
                  }"
                  >{{ hrStore.openApprovals.length }}</span
                >
              </div>
            </router-link>
          </div>
        </div>

        <div
          :class="`items absolute right-2 z-main-menu flex items-start items-center gap-4 pt-2 md:relative md:pt-0`"
        >
          <transition name="fade" appear mode="out-in">
            <div v-if="userStore.savingCount > 0">
              <CloudUploadIcon class="block h-6 w-6" title="Saving..." />
            </div>
          </transition>
          <BryntumCombo
            v-if="router.currentRoute.value.name === 'Planning'"
            :items="bcPersistStore.companies"
            placeholder="Company"
            :editable="false"
            displayField="displayName"
            valueField="name"
            @change="updatePlanningCompany"
            :value="userPersistStore.planning.selectedCompany"
            :cls="`rounded-md company-dropdown border-0  md:block md:static w-40 h-7 h-auto`"
          />
          <!--          loadingCount (tmp): {{ userStore.loadingCount }}-->
          <RefreshIcon
            v-if="authStore.isAuthenticated"
            @click="refreshBcData"
            :disabled="userStore.loadingCount > 0 || userStore.savingCount > 0"
            :class="{
              loading: userStore.loadingCount > 0 || userStore.savingCount > 0,
            }"
            class="h-6 w-6 cursor-pointer"
          ></RefreshIcon>
          <TheUserDropdownMenu />
        </div>
      </div>
    </header>
  </Disclosure>
</template>

<script setup>
import { computed } from "vue";
import { Disclosure, DisclosureButton } from "@headlessui/vue";
import {
  MenuIcon,
  XIcon,
  RefreshIcon,
  CloudUploadIcon,
} from "@heroicons/vue/outline";
import { useRouter } from "vue-router";
import { useUserPersistStore } from "@/stores/userPersist.js";
import { useHRStore } from "@/stores/hr";
import { refreshBcData } from "@/router/router.js";
import { useUserStore } from "@/stores/user.js";
import { useBcPersistStore } from "@/stores/bcPersist.js";
import { BryntumCombo } from "@bryntum/scheduler-vue-3";
import { useAuthStore } from "@/stores/auth.js";
import { onMounted, onUnmounted, ref } from "vue";

const bcPersistStore = useBcPersistStore();
const userPersistStore = useUserPersistStore();
const hrStore = useHRStore();
const userStore = useUserStore();
const authStore = useAuthStore();

const router = useRouter();

const menuBreakpoint = ref("md");

const handleResize = () => {
  if (
    userPersistStore.linkedResource.timeSheetUserType.toLowerCase() === "admin"
  ) {
    menuBreakpoint.value = "xl";
  } else {
    menuBreakpoint.value = "md";
  }
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const menuItems = computed(() => {
  const r = router.getRoutes().filter((r) => r?.meta.isMainMenu);
  if (userPersistStore.errors.length > 0) {
    r.push(router.getRoutes().find((r) => r.name === "Errors"));
  }
  if (
    userPersistStore?.actualResource?.timeSheetUserType.toLowerCase() ===
    "admin"
  ) {
    r.push(router.getRoutes().find((r) => r.name === "Admin"));
  }
  return r;
});

function updatePlanningCompany(e) {
  userPersistStore.planning.selectedCompany = e.value;
  // location.reload();
}
</script>

<style>
.router-link-hamburger {
  @apply block border-l-4 py-2 pl-3 pr-4 text-base font-medium;
}

.router-link-active {
  @apply bg-white bg-opacity-20;
}

.router-link-active-hamburger {
  @apply border-primary-500 bg-primary-500 text-white;
}

.loading {
  animation: loading-animation 1s infinite;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
