<template>
  <BryntumDateField
    :value="formattedTime"
    format="DD/MM/YY"
    @input="setTime($event.value)"
    @change="setTime($event.value)"
    cls="ml-0"
    :autoClose="true"
    :min="props.min"
    :editable="tru"
    :max="props.max"
    :multiSelect="props.multiSelect"
    v-bind="$attrs"
    :weekStartDay="1"
  ></BryntumDateField>
</template>

<script>
// use normal <script> block to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed } from "vue";
import { BryntumDateField } from "@bryntum/scheduler-vue-3";
import { debounce, formatJSDateToBCDate } from "@/utils/Utils.js";
import { DateHelper } from "@bryntum/scheduler";

const props = defineProps({
  modelValue: {},
  multiSelect: { type: Boolean, default: false },
  min: { default: null },
  max: { default: null },
});
const emits = defineEmits(["update:modelValue"]);

const formattedTime = computed({
  get() {
    const tempDate = new Date(props.modelValue);
    tempDate.setHours(0, 0, 0, 0);
    return tempDate;
  },
  set(value) {
    if (props.min && value < props.min) {
      console.log("invalid date", value, props.min);
    } else if (props.max && value > props.max) {
      console.log("invalid date", value, props.max);
    } else {
      debouncedEmit(formatJSDateToBCDate(value));
      console.log("SET DATE", value);
    }
  },
});

function setTime(value) {
  formattedTime.value = value;
}

const debouncedEmit = debounce((value) => {
  emits("update:modelValue", value);
}, 1000);
</script>

<style>
.b-pickerfield [type="text"] {
  border-width: 0 !important;
  background-color: unset;
  padding: 0.1rem 0.5rem;
}
</style>
