import { DateHelper } from "@bryntum/scheduler";

export function throttle(fn, wait) {
  let previouslyRun, queuedToRun;

  return function invokeFn(...args) {
    const now = Date.now();

    queuedToRun = clearTimeout(queuedToRun);

    if (!previouslyRun || now - previouslyRun >= wait) {
      fn.apply(null, args);
      previouslyRun = now;
    } else {
      queuedToRun = setTimeout(
        invokeFn.bind(null, ...args),
        wait - (now - previouslyRun)
      );
    }
  };
}

/**
 * Example: const processChange = debounce(() => fetchResults());
 */
export function debounce(func, timeout = 1000) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function secondsToFormattedDateTime(seconds) {
  const date = new Date(seconds);

  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();
  let h = date.getHours();
  let min = date.getMinutes();

  if (min < 10) min = "0" + min;
  if (h < 10) h = "0" + h;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return `${dd}/${mm}/${yyyy} - ${h}:${min}`;
}

export function secondsToFormattedTime(value, hideSeconds = false) {
  let hours = Math.max(Math.floor(value / 3600), 0);
  let minutes = Math.max(Math.floor((value % 3600) / 60), 0);
  let seconds = Math.max(Math.floor(value % 60), 0);
  while (seconds.toString().length < 2) {
    seconds = "0" + seconds;
  }
  while (minutes.toString().length < 2) {
    minutes = "0" + minutes;
  }
  while (hours.toString().length < 2) {
    hours = "0" + hours;
  }

  return hideSeconds ? `${hours}:${minutes}` : `${hours}:${minutes}:${seconds}`;
}

export function copyObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function timeToQuantity(value) {
  //keep sign
  const sign = value.indexOf("-") > -1 ? "-" : "+";

  const values = value.split(":");
  let hours = parseInt(values[0]);

  //remove negative values
  hours = Math.abs(hours);
  if (isNaN(hours)) {
    hours = 0;
  }
  let minutes = parseInt(values[1]);
  if (isNaN(minutes)) {
    minutes = 0;
  }
  minutes = minutes / 60;
  let r = hours + minutes;

  //add sign again
  if (sign === "-") {
    r = r * -1;
  }
  return r;
}

export function formatQuantity(value, unit) {
  switch (unit) {
    case "HOUR":
      return quantityToTime(value, false);
    default:
      return `${Math.round(value * 100) / 100} ${unit}`;
  }
}

export function quantityToTime(value, limit = true, leading = true) {
  // console.log("Gert: quantityToTime:", value);
  const sign = value < 0 ? "-" : "";
  let hours = Math.floor(Math.abs(value));
  if (limit) {
    if (hours >= 24) {
      return `23:59`;
    }
  }
  let minutes = Math.round((Math.abs(value) % 1) * 60);
  minutes = Math.min(minutes, 59);
  while (minutes.toString().length < 2) {
    minutes = "0" + minutes;
  }
  if (leading) {
    while (hours.toString().length < 2) {
      hours = "0" + hours;
    }
  }
  return `${sign}${hours}:${minutes}`;
}

export function formatIsoDate(value) {
  // console.log("DateHelper.locale", DateHelper.locale, value);
  return new Date(value).toLocaleDateString(DateHelper.locale);
}

export function wait(millies) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, millies);
  });
}

export function convertToNearestHalfDay(date) {
  //we round dates back to 0 or 12 hours
  const ONE_HOUR = 1000 * 60 * 60;
  const HALF_A_DAY = ONE_HOUR * 12;
  let r = new Date(
    Math.round(date.getTime() / HALF_A_DAY) * HALF_A_DAY + ONE_HOUR
  );
  console.log("date:", date, r);
  return r;
}

export function replaceBcBreaks(text) {
  if (text) {
    return text.replace(/\n/g, "<br>");
  } else {
    return "";
  }
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(";");
  localStorage.clear();

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCaretPosition(el, pos) {
  if (el.setSelectionRange) {
    el.focus();
    el.setSelectionRange(pos, pos);
  } else if (el.createTextRange) {
    let range = el.createTextRange();
    range.collapse(true);
    range.moveEnd("character", pos);
    range.moveStart("character", pos);
    range.select();
  }
}

export function get2DigitsIf1digit(number) {
  return `${number < 10 ? "0" : ""}${number}`;
}

export function generateDisplayNameResource(name) {
  const splitName = name.split(/ (.*)/s);
  return `${splitName[0]} ${onlyCapitalLetters(splitName[1])}`;
  function onlyCapitalLetters(str) {
    return str ? str.replace(/[^A-Z0-9]+/g, "") : "";
  }
}

export function formatJSDateToBCDate(date) {
  if (typeof date === "string") {
    date = new Date(date);
  }
  if (date) {
    return `${date.getFullYear()}-${get2DigitsIf1digit(
      date.getMonth() + 1
    )}-${get2DigitsIf1digit(date.getDate())}`;
  } else {
    return "";
  }
}

export function formatDateWithDay(date) {
  if (typeof date === "string") {
    date = new Date(date);
  }
  const options = {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  };
  return date.toLocaleDateString("en-BE", options);
}

export function getUserNameFromEmail(email) {
  if (!email) {
    throw new Error("Email is required: " + email);
  }
  if (!email.includes("@")) {
    throw new Error("Invalid Email: " + email);
  }
  return email.split("@")[0];
}
