import { notify } from "@kyvg/vue3-notification";

let userStore;
let bcPersistStore;

let isBeforeunloadListenerAdded = false;

const beforeUnloadListener = async (event) => {
  notify({
    title: "Your changes are still saving!",
    text: "Please wait until the saving indicator (top right) is done.",
    duration: 10000,
  });

  event.preventDefault();
  return (event.returnValue =
    "Do you want to leave B·U·T Timesheet? Not all changes are saved.");
};

function checkUnloadWarning(userStore, resourceChangeBuffer) {
  if (
    userStore?.eventChangeBuffer?.length > 0 ||
    resourceChangeBuffer ||
    userStore?.fieldUpdateBuffer?.length > 0 ||
    userStore?.savingCount > 0
  ) {
    if (!isBeforeunloadListenerAdded) {
      isBeforeunloadListenerAdded = true;
      window.addEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
    }
  } else {
    if (isBeforeunloadListenerAdded) {
      isBeforeunloadListenerAdded = false;
      window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
    }
  }
}

export function initUnloadWarning(userStore_, bcPersistStore_) {
  userStore = userStore_;
  bcPersistStore = bcPersistStore_;

  // 1. check on init
  checkUnloadWarning(userStore, bcPersistStore.resourceChangeBuffer);

  userStore.$subscribe((mutation, state) => {
    if (mutation.storeId === "user") {
      // 2. check on each change
      checkUnloadWarning(state, bcPersistStore.resourceChangeBuffer);
    }
  });

  bcPersistStore.$subscribe((mutation, state) => {
    if (mutation.storeId === "bcPersist") {
      // 2. check on each change
      checkUnloadWarning(userStore, state.resourceChangeBuffer);
    }
  });
}
