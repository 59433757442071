<template>
  <div class="flex w-full items-center justify-center">
    <div class="rounded-lg bg-white p-16 shadow-2xl">
      <h2 class="mb-4 text-2xl font-extralight">
        Welcome to the B·U·T Timesheet Tool
      </h2>
      <div v-if="!authStore.isAuthenticated">
        <p>Please log in with your Microsoft account.</p>
        <button
          @click="login()"
          class="btn mt-4 bg-primary uppercase text-white"
        >
          Log in
        </button>
      </div>
      <div v-else class="flex items-center gap-4">
        <p class="text-lg">Loading data ...</p>
        <Spinner :size="16" color="black" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/auth.js";
import { useRouter } from "vue-router";
import Spinner from "@/components/Spinner.vue";

const router = useRouter();
const authStore = useAuthStore();

async function login() {
  await authStore.signIn();
  router.push({ name: "Home" });
}
</script>
