import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { v4 as uuidv4 } from "uuid";
import { notify } from "@kyvg/vue3-notification";
import { bcEnvironment } from "@/../version.json";

export const DEFAULT_USER_SETTINGS = {
  storedIdleTime: 0,
  lastActivity: 0,
  projectFavorites: [],
  taskFavorites: [],
  verticalCalendar: null,
  taskOrder: [],
};

export const useUserPersistStore = defineStore("userPersist", {
  state: () =>
    useStorage(
      `timesheet-userPersist-${bcEnvironment}`,
      {
        linkedResource: null,
        actualResource: null,
        enableIdleDetection: true,
        idleNotifications: true,
        filterString: "",
        sortingFields: [],
        errors: [],
        planning: {
          zoomLevel: null,
          selectedCompany: null,
          projectsVisible: true,
          remoteWorkVisible: true,
        },
        userSettings: DEFAULT_USER_SETTINGS,
        showLastCommitted: false,
        committedTasksSetting: null,
      },
      null,
      { mergeDefaults: true }
    ),
  getters: {
    getSchedulerTimeZone: (state) => {
      switch (state.planning.selectedCompany) {
        case "BUT PTE LTD":
          return "Asia/Singapore";
        case "BUT NV (UAE)":
          return "Asia/Dubai";
        case "BUT NV (BELGIUM)":
          return "Europe/Brussels";
        case "BUT.CH SA":
          return "Europe/Brussels";
        default:
          console.warn(
            "getSchedulerTimeZone - Unknown company:",
            state.planning.selectedCompany
          );
          return "Europe/Brussels";
      }
    },
    linkedEmployeeNo: (state) => {
      const employeeNo = state.linkedResource.employeesPTE[0]
        ? state.linkedResource.employeesPTE[0].no
        : "";
      return employeeNo;
    },
  },
  actions: {
    pushError(error, addToErrorList = true) {
      const bCErrors = [500, 501, 502, 503, 504];

      // console.log("Errr", error);

      if (typeof error === "string") {
        error = {
          message: error,
        };
      }

      const obj = {
        error: error,
        code: error?.response?.data?.error?.code || error.code || "N/A",
        message:
          error?.response?.data?.error?.message ||
          error.message ||
          typeof error?.message === "string"
            ? error.message
            : JSON.stringify(error),
        stack: error?.stack,
        time: new Date(),
        guid: uuidv4(),
      };
      //remove CorrelationId from error message
      if (obj.message.indexOf("CorrelationId:") > -1) {
        obj.message = obj.message.substring(
          0,
          obj.message.indexOf("CorrelationId:")
        );
      }

      if (addToErrorList) {
        this.errors.unshift(obj);
        const MAX_ERRORS = 25;
        //keep only the last X recs, remove older ones
        if (this.errors.length > MAX_ERRORS) {
          this.errors = this.errors.slice(0, MAX_ERRORS);
        }
      }
      if (bCErrors.includes(obj.status)) {
        notify({
          title: "Business Central Server Error",
          text: "The Business Central API can not be reached. Data might not be saved!",
          duration: 10000,
        });
      } else {
        notify({
          title: "Error",
          text: obj.message,
          duration: 10000,
        });
      }
    },
    deleteError(guid) {
      const index = this.errors.findIndex((e) => e.guid === guid);
      // console.log("guid", guid, index);
      if (guid && index > -1) {
        this.errors.splice(index, 1);
      } else {
        console.error("Could not delete error with guid", guid);
      }
    },
    deleteAllErrors() {
      this.errors = [];
    },
  },
});
